import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Cookies from "universal-cookie";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Card from "./Card";
import img1 from "../../Assets/images/where/img1.png";
import img2 from "../../Assets/images/where/img2.png";
import img3 from "../../Assets/images/where/img3.png";
import img16 from "../../Assets/images/where/img16.png";
import img14 from "../../Assets/images/where/img14.png";
import { Link } from "react-router-dom";
import {
  getwishlist,
  getwishlistarray,
  addwishlist,
} from "../../Hooks/filterHook";
import { getUserDatas } from "../../Hooks/useUserHook";

const cookies = new Cookies();

const Wishlist = () => {
  const [wishlist, setWishlist] = useState([]);
  const [userDatas, setUserDatas] = useState({});
  const [wishedlist, setWishedlist] = useState([]);
  const [count, setCount] = useState(false);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    async function fetchdata() {
      let email = await cookies.get("email");
      let wishlist = await getwishlist(email);
      console.log("wishlist return data", wishlist.data.record);

      setUserDatas(getUserDatas());
      setWishlist(wishlist.data.record);
      // setWishedlist(wishlist.data.record[0].wishList);
      // console.log("card data",randomdata);
    }
    fetchdata();
  }, []);

  const wishlistarray = async (id, mail) => {
    let payload = {
      roomid: id,
      emailid: mail,
    };
    await addwishlist(payload)
      .then(async (data) => {
        console.log("wishlist r eturn: ", data);
        cookies.set(
          "User-Datas",
          data.data.record,
          { path: "/" },
          { expires: new Date(Date.now() + 600000) }
        );

        let wishlist = await getwishlist(mail);
        console.log("wishlist return data", wishlist.data.record);

        setUserDatas(getUserDatas());
        setWishlist(wishlist.data.record);
        // const wishlistarray = await getwishlistarray(mail);

        // setWishedlist(wishlistarray);
      })
      .catch((e) => console.log("wishlist return error", e));
  };

  return (
    <>
      <Header />

      <div className="wishlist my-4 my-lg-5 mh-100vh-header_wishlist">
        <div className="container container_custom">
          <h2 className="mb-1 fw-700">Saved Listings</h2>
          <p className="mb-0 fw-500">
            See all the listings you’ve saved in one place{" "}
          </p>
          <div className="mb-4 mt-4">
            <div className="card-5 row">
              {wishlist && wishlist?.length > 0 && wishlist.map((i) => {
                console.log("wishlistedata", i, !i.isDeactivate, i.isListed, !i.isRejected)
                if (!i.isDeactivate && i.isListed && !i.isRejected) {
                  return (
                    <div className="col-xl-4 col-lg-4 col-mg-6 col-sm-6 col-12 mb-3">
                      <Card
                        data={i}
                        userData={userDatas}
                        wishlistarray={wishlistarray}
                      />
                      
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Wishlist;
